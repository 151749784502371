import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";

const CONVERSATION = () => {
    return(
        <div>
            <Navbar />
            <Footer />
        </div>
    )
}

export default CONVERSATION;