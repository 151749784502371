import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Team = () => {

    useEffect(() => {
        AOS.init({
            duration: 1400,
            easing: 'ease-in-out',
            mirror: true
        });
    }, []);

    return (
        <section className="py-6 bg-gray-900 text-gray-100">
            <div className="container flex flex-col items-center justify-center p-4 mx-auto space-y-8 sm:p-10">
                <h1 className="text-4xl font-bold leading-none text-center sm:text-5xl">Our team</h1>
                <p className="max-w-2xl text-center dark:text-gray-400">At a assumenda quas cum earum ut itaque commodi saepe rem aspernatur quam natus quis nihil quod, hic explicabo doloribus magnam neque, exercitationem eius sunt!</p>
                <div className="flex flex-row flex-wrap-reverse justify-center">
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-up-right">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?0" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-up-right">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?1" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-up">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?2" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-up">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?3" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-up">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?4" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-up-left">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?5" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-up-left">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?5" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-right">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?5" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-right">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?5" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="flip-left">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?5" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="flip-left">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?5" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>                    <div className="flex flex-col justify-center m-8 text-center" data-aos="flip-left">
                    <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?5" />
                    <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                    <p className="dark:text-gray-400">Visual Designer</p>
                </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-left">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?5" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>
                    <div className="flex flex-col justify-center m-8 text-center" data-aos="fade-left">
                        <img alt="" className="self-center flex-shrink-0 w-24 h-24 mb-4 bg-center bg-cover rounded-full dark:bg-gray-500" src="https://source.unsplash.com/100x100/?portrait?5" />
                        <p className="text-xl font-semibold leading-tight">Leroy Jenkins</p>
                        <p className="dark:text-gray-400">Visual Designer</p>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Team;